<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ใบสั่งสินค้า
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
          depressed
          @click="$router.push({path: '/stock/'})"
          color="primary"
          class="mr-4"
      >ดูสินค้า
      </v-btn>
      <v-btn
          depressed
          v-if="$store.state.authUser.user_permissions.includes('add_purchaseorder')"
          @click="$router.push({path: '/stock/po/create'})"
          color="success"
      >+ เพิ่มใบสั่งสินค้าใหม่
      </v-btn>
      <v-btn icon @click="showFilter = !showFilter">
        <v-icon>
          mdi-filter
        </v-icon>
      </v-btn>
    </v-toolbar>
    <!--    <div class="mx-4 box box-2" style="min-height: 100%;">-->
    <!--      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">-->
    <!--        <v-col-->
    <!--            cols="9"-->
    <!--            style="height: 100%"-->
    <!--        >-->
    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" class="pl-4 pb-4">
          <v-card
              style="height: 100%; overflow: auto"
              class=" rounded-lg elevation-2"
              outlined
              tile
          >
            <div id="table-warp" style="height: 100%">
              <v-data-table
                  loading-text="Loading... Please wait"
                  style="height: 100%"
                  :custom-filter="customFilter"
                  :headers="headers"
                  :items="pos"
                  class="elevation-1"
                  no-data-text="ไม่มีข้อมูล"
                  :header-props="{ sortIcon: null }"
                  :items-per-page="itemsPerPage"
                  :footer-props="footerProps"
                  @click:row="gotoPODetail"
                  :search="search"

              >
                <template v-slot:item.po_item="{item}">
                  <p class="text-truncate" style="margin-bottom: 0">
                    {{ showProduct(item.po_item) }}
                  </p>
                </template>

                <template v-slot:item.total_price="{item}">
                  <p style="margin-bottom: 0">
                    {{ $currency(item.total_price).format() }}
                  </p>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-btn
                      :color="colorStatus(item)"
                      outlined
                      raised
                      small
                      block
                  >{{ item.status }}
                  </v-btn>
                </template>

                <template slot="body.append">
                  <tr style="background-color: #eaf8ff">
                    <td>
                      รวม
                    </td>
                    <td></td>
                    <td></td>
                    <td class="text-left"><b>{{ $currency(sumTotalPrice).format() }} บาท</b></td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </pane>
        <!--        </v-col>-->
        <!--        <v-col-->
        <!--            cols="3"-->
        <!--            style="height: 100%"-->
        <!--        >-->
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard @search="loadProduct" :search-option="[{text:'สินค้า',value:'product_name'}]" date price-min-max
                      po-supplier
                      :backwards="1"
                      po-status/>
        </pane>
      </splitpanes>
    </div>
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </div>-->
  </div>
</template>

<script>
import axios from "axios";
import DateTime from "luxon/src/datetime";
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";

export default {
  components: {FilterCard, Splitpanes, Pane},
  name: "POList",
  data() {
    return {
      sumTotalPrice: 0,
      showFilter: true,
      pos: [],
      headers: [
        {
          text: 'รหัสสินค้า',
          value: 'id',
          width: 80,
          align: 'center'
        },
        {
          text: 'วันที่สั่ง',
          value: 'order_date',
          width: 80,
          align: 'center'
        },
        {
          text: 'สินค้า',
          value: 'po_item',
        },
        {
          text: 'ราคารวม',
          value: 'total_price',
          width: 80,
          align: 'center'
        },

        {
          text: 'สถานะ',
          value: 'status',
          width: 100,
          align: 'center'
        },
      ],
      itemsPerPage: 0,
      footerProps: {'items-per-page-options': []},
      optionSearch: '',
      search: '',
      modal: false,
      dates: [],
      timeFilterRadio: "all",
      // timeRadio: [
      //   {
      //     value: "all",
      //     label: "ทั้งหมด",
      //     fn: function (items) {
      //       return items
      //     }
      //   },
      //   {
      //     value: "today",
      //     label: "วันนี้",
      //     fn: function (items, column) {
      //       return items.filter(item => item[column] === DateTime.now().toFormat('yyyy/LL/dd'))
      //       // return DateTime.now().toFormat('dd/LL/yyyy')
      //     }
      //   },
      //   {
      //     value: "yesterday",
      //     label: "เมื่อวาน",
      //     fn: function (items, column) {
      //       return items.filter(item => item[column] === DateTime.now().minus({days: 1}).toFormat('yyyy/LL/dd'))
      //     }
      //   },
      //   {
      //     value: "last-week",
      //     label: "7 วันที่แล้ว",
      //     fn: function (items, column) {
      //       return items.filter(item => item[column] >= DateTime.now().minus({days: 7}).toFormat('yyyy/LL/dd') && item[column] <= DateTime.now().toFormat('yyyy/LL/dd'))
      //     }
      //   },
      //   {
      //     value: "last-month",
      //     label: "เดือนที่แล้ว",
      //     fn: function (items, column) {
      //       return items.filter(item => {
      //         return DateTime.fromFormat(item[column], 'yyyy/LL/dd').toFormat('yyyy/LL') === DateTime.now().minus({months: 1}).toFormat('yyyy/LL')
      //       })
      //     }
      //   },
      // ],
      filteredPO: []
    }
  },
  created() {
    // this.loadProduct()
  },
  methods: {
    loadProduct(params) {
      this.$store.state.isLoading = true
      axios.get('/api/stocks/po/' + params)
          .then(res => {
            this.pos = res.data.data
            this.pos.forEach(c => {
              this.sumTotalPrice += parseFloat(c.total_price)
            })

            // this.updateTable();
            // this.$store.state.isLoading = false
          })
          .catch(e => this.$store.state.snackbar = {
            text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
            visible: true,
            color: 'error'
          })
          .finally(() => {
            this.$store.state.isLoading = false
          })
    },
    // updateTable() {
    //   let tableHeight = document.getElementById('table-warp').offsetHeight;
    //   this.itemsPerPage = ((tableHeight) / 48) - 2 | 0;
    //   if (this.pos.length < this.itemsPerPage) this.itemsPerPage = this.pos.length;
    //   this.footerProps['items-per-page-options'].unshift(this.itemsPerPage);
    // },
    gotoPODetail(row) {
      this.$router.push({path: `/stock/po/${row.id}`})
    },
    // inRangeItem(range, items, column) {
    //   if (range.length === 1) {
    //     this.filteredPO = items.filter(item => DateTime.fromFormat(item[column], 'yyyy/LL/dd').toISODate() === DateTime.fromFormat(range[0], 'yyyy-LL-dd').toISODate())
    //   } else {
    //     range.sort()
    //     this.filteredPO = items.filter(item =>
    //         DateTime.fromFormat(item[column], 'yyyy/LL/dd').toISODate() >= DateTime.fromFormat(range[0], 'yyyy-LL-dd').toISODate()
    //         && DateTime.fromFormat(item[column], 'yyyy/LL/dd').toISODate() <= DateTime.fromFormat(range[1], 'yyyy-LL-dd').toISODate())
    //   }
    // },
    customFilter(value, search, item) {
      search = this.search.toString().toLowerCase()
      return item[this.optionSearch].toString().includes(search)
    },
    colorStatus(item) {
      if (item.status === 'รอรับสินค้า') {
        return 'orange'
      } else if (item.status === 'ยกเลิก') {
        return 'gray'
      } else if (item.status === 'ยกเลิก (กดรับแล้ว)') {
        return 'red'
      } else {
        return 'success'
      }
    },
    showProduct(item) {
      console.log(item)
      let string = ''
      item.forEach((i, index) => {
        if (i.name !== 'ค่าส่ง') {
          string += (`${i.name} ${index !== item.length - 1 ? ', ' : ''}`)
        }
      })
      return string
    },
    // formatDate(second) {
    //   return DateTime.fromSeconds(second).toFormat('dd/LL/yyyy')
    // },
  },
  computed: {
    dateRangeText() {
      if (this.dates === null) {
        return ""
      } else {
        // this.dates.sort()
        return this.dates.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')).sort().join(' - ')
      }
    },
  },
  watch: {
    // timeFilterRadio(value, oldValue) {
    //
    //   if (value !== 'select-range') {
    //     let [current] = this.timeRadio.filter(time => time.value === value)
    //     this.filteredPO = current.fn(this.pos, "order_date")
    //   } else {
    //     this.modal = true
    //     this.tmpTimeRadio = oldValue
    //   }
    // },
    // pos() {
    //   let [current] = this.timeRadio.filter(time => time.value === this.timeFilterRadio)
    //   this.filteredPO = current.fn(this.pos)
    // }
  }
}
</script>

<style scoped>

</style>
